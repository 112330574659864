import * as React from 'react';
import PropTypes from "prop-types";
import {DownloadArrow} from '../content/img/downloadArrow';
import {purple} from './contentAux';
import * as styles from './downloadButton.module.scss';



export class DownloadButton extends React.Component {
  	constructor(props) {
        super(props);
        this.state = {
        	inFocus: false,
        };

        this.toggleFocus = this.toggleFocus.bind(this);
    }

    toggleFocus() {
    	this.setState({inFocus: !this.state.inFocus});
    }

    getTextClass() {
    	return this.state.inFocus ? `${styles.text} ${styles.inFocus}` : styles.text;
    }

    render() {
        return (
           
			<div className={styles.container}>
                

               <a 
                onMouseEnter={this.toggleFocus} 
                onMouseLeave={this.toggleFocus} 
                className={styles.row} 
                href={this.props.url} 
                download={this.props.url} >
                    <DownloadArrow 
                    	arrowFill={this.state.inFocus ? "white" : purple} 
                    	circleFill={this.state.inFocus ? purple : "transparent"} 
                    	circleStroke={purple}
                    	className={styles.arrow}
                    />
                    <p className={this.getTextClass()}>{this.props.text}</p>

               </a>


            </div>
                    
         
        );
    }
}

DownloadButton.propTypes = {

    text: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,


};
