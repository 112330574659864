import React from "react";


export const DownloadArrow = ({
  className = "",
  circleFill = "transparent",
  circleStroke = "000000",
  arrowFill = "#000000"}) =>


<svg xmlns="http://www.w3.org/2000/svg" 
  className={className}    
  viewBox="0 0 24 24">
 <circle
     r="11.114398"
     cy="12"
     cx="12"
     id="path822"
     style={{opacity:1,fill:circleFill,stroke:circleStroke,strokeWidth:1.7664187,strokeMiterlimit:4,strokeDasharray:"none",strokeOpacity:1}} 
     />
  <path
     id="path4"
     style={{fill:arrowFill}}
     d="M11 5v11.17l-4.88-4.88c-.39-.39-1.03-.39-1.42 0-.39.39-.39 1.02 0 1.41l6.59 6.59c.39.39 1.02.39 1.41 0l6.59-6.59c.39-.39.39-1.02 0-1.41-.39-.39-1.02-.39-1.41 0L13 16.17V5c0-.55-.45-1-1-1s-1 .45-1 1z" />

</svg>;

