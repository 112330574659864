import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import {ContentPage} from "../contentPage";
import {DownloadButton} from "../../../common/downloadButton";
import * as styles from './rolunkPage.module.scss';

const Roles = (panel) => 
<div className={styles.noBox}>
	{panel && panel.map((role, i) =>
		<div className={styles.roleRow} key={i}>
			<p className={styles.roleName}>{role.memberPosition}:</p>
			{role.members && role.members.map((p, i) => 
			<div className={styles.personRow} key={i}>
				<p className={styles.personName}>{p.memberName} {p.memberEmail && <span className={styles.endash}>–&nbsp;</span>}</p>
				<p className={styles.personEmail}>{p.memberEmail}</p>
			</div>)}
		</div>
	)}
</div>;

const Adatok = (adatok, tagjegyzek) =>
<div className={styles.box}>
	{adatok && adatok.map((adat, i) => 
		<div key={i} className={styles.adatRow}>
			<p className={styles.names}>{adat.adatNev}:</p>
			<p className={styles.values}>{adat.adatTartalom}</p>
		</div>
	)}
	<DownloadButton text="Tagjegyzék" url={tagjegyzek[0]}/>
</div>;

export default function RolunkPage() {
	const data = useStaticQuery(graphql`
		query RolunkQuery {
			rolunkData: allFile(filter: {sourceInstanceName: {eq: "data"}, name: {eq: "rolunk"}}) {
				edges {
					node {
						childMarkdownRemark {
							frontmatter {
								tagjegyzek
								adatok {
									adatNev
									adatTartalom
								}
								panel {
									memberPosition
									members {
										memberEmail
										memberName
									}
								}
								files {
									title
									file
								}
							}
							html
						}
					}
				}
			}
		}	
	`);

	const {html, frontmatter} = data.rolunkData.edges[0].node.childMarkdownRemark;
	const {tagjegyzek, adatok, panel, files} = frontmatter;

	return (
		<ContentPage activePage={3}>
			<div className={styles.container}>
				<div className={styles.inner}>
						<div className={styles.noBox}>
							<p className={styles.lsTitle}>A szövetség célja és tevékenysége</p>
							<div dangerouslySetInnerHTML={{__html: html}}/>
						</div>

						{Adatok(adatok, tagjegyzek)}
				</div>
				<div className={styles.inner}>
					<div className={styles.box}>
							<p className={styles.lsTitle}>Dokumentumok</p>
							<div className={styles.dwlBox}>
								{files && files.map((file, i) => <DownloadButton key={i} text={file.title} url={file.file.length && file.file[0]}/>)}
							</div>
					</div>
					{Roles(panel)}
				</div>	
			</div>
		</ContentPage>
	);
}
